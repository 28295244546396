<template class="test">
  <div class="login">
    <div class="login__wrap">
      <img src="/logo.png" alt="Logo enwam">
      <div class="login__form space-y-4">
        <div class="form-group">
          <label for="user">Usuario</label>
          <input v-model="user.email" class="input bg-gray-100" type="email" name="user" id="user">
          <span v-if="this.validationErrors.email" class="error">{{ this.validationErrors.email }}</span>
        </div>
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input @keydown.enter="login" v-model="user.password" class="input bg-gray-100" type="password" name="user" id="password">
          <span v-if="this.validationErrors.password" class="error">{{ this.validationErrors.password }}</span>
        </div>
        <button :class="{'is-loading': progress}" type="button" @click="login" class="btn btn---block">
          <span v-if="!progress">Ingresar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase/firebaseConfig'
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero'
  },
  string: {
    email: 'Debe ser  un email'
  }
})

export default {
  name: 'Login',
  data () {
    return {
      progress: false,
      user: {
        email: '',
        password: ''
      },
      schema: yup.object().shape({
        email: yup.string().email().required().label('Email'),
        password: yup.string().required().label('Contraseña')
      }),
      validationErrors: {}
    }
  },
  methods: {
    async login () {
      try {
        this.progress = true
        this.validationErrors = {}
        await this.schema.validate({ ...this.user }, { abortEarly: false })
        await auth.signInWithEmailAndPassword(this.user.email, this.user.password)
        const tokenResult = await auth.currentUser.getIdTokenResult()
        console.log(tokenResult.claims)
        if (tokenResult.claims.client) {
          this.$store.dispatch('setUser') // Set in store
        } else {
          await auth.signOut()
        }
        this.$store.dispatch('hideLogin')
        await this.$router.push('/')
      } catch (err) {
        console.log(err)
        this.$toast.error('Error al ingresar')
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        }
        if (err.code === 'auth/invalid-email') {
          this.validationErrors.email = 'Email inválido'
        } else if (err.code === 'auth/user-disabled') {
          this.validationErrors.email = 'Usuario eliminado'
        } else if (err.code === 'auth/user-not-found') {
          this.validationErrors.email = 'Usuario no existe'
        } else if (err.code === 'auth/wrong-password') {
          this.validationErrors.password = 'Contraseña incorrecta'
        }
      } finally {
        this.progress = false
      }
    }
  }
}
</script>
